// generateSignedUrl.ts
import fetch from 'node-fetch';

interface ApiResponse {
  signedUrl: string;
}

async function generateSignedUrl(url: string): Promise<string> {
  const apiKey = process.env.REACT_APP_API_KEY;

  if (!apiKey) {
    throw new Error('API_KEY environment variable is not set.');
  }

  const response = await fetch('https://rarity.api.blockviper.com/api/generateSignedUrl', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ apiKey, url }),
  });

  if (!response.ok) {
    throw new Error(`Server responded with status ${response.status}`);
  }

  const data = (await response.json()) as ApiResponse; // Type assertion here
  return data.signedUrl;
}

export default generateSignedUrl;
