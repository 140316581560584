import { useEffect, useState } from "react";
import { IconLink } from './icons';
import { ThemeSubscription } from "../helpers/theme";
import { getChains } from '../helpers/urls';

type Chain = {
  chain: string;
  image: string;
  external_link: string;
};

export const Header = ({ chainId }: { chainId: string }) => {
    const [isDark, setIsDark] = useState(true);
    const [selectedChain, setSelectedChain] = useState(chainId);
    const [chains, setChains] = useState<Chain[]>([]);

    useEffect(() => {
        ThemeSubscription.subscribe(setIsDark);
    }, []);

    useEffect(() => {
        setSelectedChain(chainId);
    }, [chainId]);

    useEffect(() => {
        (async () => {
            try {
                const chainsURL = await getChains();
                const response = await fetch(chainsURL); // Fetch the data from the URL
                const data = await response.json(); // Parse the JSON response
                setChains(data.chains || []);
            } catch (error) {
                console.error('Error fetching chains data:', error);
            }
        })();
    }, []);
    

    const handleChangeChain = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedChain(event.target.value);
        window.location.assign(`/${event.target.value}`);
    };

    return (
        <>
<div className="header" style={{ display: 'flex', flexDirection: 'row', padding: 8, alignItems: 'center' }}>
    <div style={{ fontSize: 36, marginRight: 16 }}>
        <a href="/">
            <img
                src={isDark ? '/logo192.png' : '/logo192.png'}
                alt="site-logo"
                style={{ width: 50, marginRight: 8, verticalAlign: 'middle' }}
            />
            <span className="hideMobile">blockViper</span> Rarity Analyzer
        </a>
    </div>
    <div style={{ flexGrow: 1 }}></div>
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <div style={{ marginRight: 16 }}>
            <select value={selectedChain} onChange={handleChangeChain}>
                {chains.map(chain => (
                    <option key={chain.chain} value={chain.chain}>{chain.chain}</option>
                ))}
            </select>
        </div>
        <div>
            <IconLink url={"https://twitter.com/blockViperSys"} icon="twitter" />
        </div>
    </div>
</div>

        </>
    );
};
