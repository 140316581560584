import { useState, useEffect } from 'react';
import { getChains } from '../helpers/urls';

// Define an interface for the chain data
interface ChainData {
    chain: string;
    image: string;
    external_link: string;
}

export const HomePage = () => {
    const [chains, setChains] = useState<ChainData[]>([]);
    // console.log("chains", chains)

    useEffect(() => {
        (async () => {
            try {
                const chainsURL = await getChains();
                const response = await fetch(chainsURL); // Fetch the data from the URL
                const data = await response.json(); // Parse the JSON response
                setChains(data.chains || []);
            } catch (error) {
                console.error('Error fetching chains data:', error);
            }
        })();
    }, []);

    return (
        <div className="homeContainer">
            <div style={{ width: '100%' }}>
                {chains.map((chain) => (
                    <a href={`/${chain.chain}`} key={chain.chain} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="homeRow">
                            <div style={{ width: '50%', textAlign: 'left' }}>
                                <img src={chain.image} alt={chain.chain} style={{ width: '50%', height: 'auto' }} />
                            </div>
                            <div style={{ width: '50%', textAlign: 'right' }}>
                                <h3>{chain.chain}</h3>
                            </div>
                        </div>

                    </a>
                ))}
            </div>
        </div>


    );
};
