import React from 'react';
import { INftRarity } from "../common";
import { BarGraphCell } from './bar-graph';
import { Icon, LoadingIndicator } from './icons';
import { SmartImage } from './smart-image';
import { MarketPlaces } from './marketPlaces';
import { OnSelectTraitValue } from './types';

export type INftRarityWithExtra = INftRarity & {
    openSeaLink?: string;
    lastSell?: {
        price: number;
        symbol?: string;
        priceUsd?: number;
    },
    listing?: {
        price: number;
        symbol?: string;
        priceUsd?: number;
    },
};

const Price = ({ price }: {
    price?: {
        price: number;
        symbol?: string;
        priceUsd?: number;
    }
}) => {

    if (!price) { return <span>{'-?-'}</span> }

    const priceColor = price.price < 0.1 ? '#FF0000'
        : price.price < 0.25 ? '#FF3300'
            : price.price < 0.5 ? '#FF7700'
                : price.price < 1 ? '#FFBB00'
                    : 'unset';

    return (
        <>
            <div style={{ display: 'inline-block', minWidth: 100, color: priceColor }}>
                <span>{price.price.toFixed(3)}</span>
                {price.symbol && (
                    price.symbol.toLowerCase() === 'eth' ? <span className='black-or-white'><Icon icon='eth' /></span>
                        : price.symbol.toLowerCase() === 'weth' ? <span style={{ color: '#ca477b' }}><Icon icon='eth' /></span>
                            : <span>{price.symbol}</span>
                )}
            </div>
            <div style={{ display: 'inline-block', minWidth: 100, color: priceColor }}>
                <span>{price.priceUsd ? ` $${price.priceUsd.toFixed(2)}` : ''}</span>
            </div>
        </>
    );
}

export const NftCardPlaceholder = (props: {}) => {
    return (
        <>
            <div className={'nft-card'}>
                <LoadingIndicator />
                <div style={{ height: 300 }}></div>
            </div>
        </>
    );
}

export const NftCard = ({ chainId, nft, onSelect, contractAddress }: {
    chainId: string,
    nft: INftRarityWithExtra,
    onSelect?: OnSelectTraitValue,
    contractAddress: string,
    tokenID?: string,
}) => {

    return (
        <>
            <div className={'nft-card'}>
                <div className='nft-card-header'>
                    <div className='nft-card-header-item'>
                        <div className='nft-card-header-item-label'>Rarity Score:</div>
                        <div className='nft-card-header-item-value'>{nft.rarityScore.toFixed(2)}</div>
                    </div>
                    <div className='nft-card-header-item'>
                        <div className='nft-card-header-item-label'>Rank:</div>
                        <div className='nft-card-header-item-value'>{nft.rank}</div>
                    </div>
                    {/*
                    <div className='nft-card-header-item-2'>
                        <div className='nft-card-header-item-label'>Last Sell:</div>
                        <div className='nft-card-header-item-value'><Price price={nft.lastSell}/></div>
                    </div>
                    <div className='nft-card-header-item-2'>
                        <div className='nft-card-header-item-label'>Listing:</div>
                        <div className='nft-card-header-item-value'><Price price={nft.listing}/></div>
                    </div>
                    */}
                    <div className='nft-card-header-item'>
                        <div className='nft-card-header-item-label'>Name:</div>
                        <div className='nft-card-header-item-value'>{nft.nft.name}</div>
                    </div>
                    <div className='nft-card-header-item'>
                        <div className='nft-card-header-item-label'>TokenId:</div>
                        <div className='nft-card-header-item-value'>{nft.nft.id}</div>
                    </div>
                </div>

                <div className='nft-card-content'>
                    {/*
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                        <IconLink url={nft.nft.external_url} icon='link'/>
                        <IconLink url={nft.openSeaLink} iconUrl='/media/opensea.png'/>
                    </div>
                    */}
                    <div className={'nft-card-image'}>
                        <SmartImage alt='nft' src={nft.nft.image} />
                    </div>

                    <div className='nft-card-stats'>
                        <div className='nft-card-stats-row' style={{ display: 'flex', fontSize: `0.8em` }}>
                            <div className='nft-card-stats-cell' style={{ textAlign: 'left', width: '50%' }}>{'Trait'}</div>
                            <div className='nft-card-stats-cell' style={{ textAlign: 'center', width: '25%' }}>{'Rarity Score'}</div>
                            <div className='nft-card-stats-cell' style={{ textAlign: 'right', width: '25%' }}>{'Commonality'}</div>
                        </div>
                        {nft.attributeRarities.map(x => (
                            <React.Fragment key={x.trait_type}>
                                {x.value !== "[Missing]" && (
                                    <div className='nft-card-stats-row' style={{ display: 'flex' }}>
                                        <div
                                            className={`nft-card-stats-cell ${onSelect ? 'link' : ''}`}
                                            style={{ textAlign: 'left' }}
                                        >
                                            <span
                                                title={x.trait_type}
                                                onClick={(e) => {
                                                    if (!onSelect) {
                                                        return;
                                                    }

                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onSelect({ traitType: x.trait_type, value: x.value });
                                                }}
                                                style={x.value !== "[Missing]" ? { fontWeight: 'bold' } : {}}
                                            >
                                                {x.value}
                                            </span>
                                        </div>
                                        {x.value !== "[Missing]" && (
                                            <>
                                                <div
                                                    className='nft-card-stats-cell numeric-value'
                                                    style={{ textAlign: 'center', width: '25%' }}
                                                >
                                                    {x.ratioScore.toFixed(2)}
                                                </div>
                                                <div
                                                    className='nft-card-stats-cell numeric-value'
                                                    style={{ textAlign: 'right', width: '25%' }}
                                                >
                                                    <BarGraphCell ratio={x.ratio} />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                {/* ... */}
                            </React.Fragment>
                        ))}

                    </div>
                    <MarketPlaces chainId={chainId} contractAddress={contractAddress} nftId={nft.nft.id} />
                </div>
            </div>
        </>
    );
};

