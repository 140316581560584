import './CssReset.css';
import './App.css';
import { NftLoader } from './components/nft-loader';
import { NftProjectLoader } from './components/nft-project';
import { Header } from './components/header';
import { Layout } from './components/layout';
import { HomePage } from './components/home-page';
import { NftProjectsLoader } from "./components/nft-projects";


const App = () => {

  return (
    <Layout>
        <Routing />
    </Layout>
  );
};

const Routing = (props: {}) => {
  const route = window.location.pathname.split('/');
  const chainId = route[1] ?? undefined;
  // console.log("chainId",chainId)
  const projectKey = route[2] ?? undefined;
  // console.log("projectKey",projectKey)
  const tokenId = route[3] ?? undefined;
  // console.log("tokenId",tokenId)

  if (chainId && projectKey && tokenId) {
    return (
      <>
      <Header chainId={chainId} />  
      <div className="App nftPage">
        <div className='nft-container-single'>
          <NftLoader chainId={chainId} projectKey={projectKey} tokenId={tokenId} contractAddress={''} />
        </div>
      </div>
      </>
    );
  }

  if (chainId && projectKey) {
    return (
      <>
      <Header chainId={chainId} />  
      <div className="App projectPage">
        <NftProjectLoader chainId={chainId} projectKey={projectKey} />
      </div>
      </>
    );
  }

  if (chainId) {
    return (
      <>
      <Header chainId={chainId} />  
      <div className="App projectsPage">
        <NftProjectsLoader chainId={chainId} />
      </div>
      </>
    );
  }

  return (
    <>
    <Header chainId={chainId} />  
    <div className="App chainsPage">
      <HomePage />
    </div>
    </>
  );
};

export default App;
