// urls.ts

// export const getProjectsJsonUrl = ()=>`/data/projects.json`;
// export const getProjectJsonUrl = (projectKey:string)=>`/data/${projectKey}/project.json`;

// export const getProjectsJsonUrl = ()=>`https://cdn.blockviper.com/rarityDev/data/projects.json`;
// export const getProjectJsonUrl = (projectKey:string)=>`https://cdn.blockviper.com/rarityDev/data/${projectKey}/project.json`;
// export const getNftJsonUrl = (projectKey:string, tokenId: string)=>`https://${distributionDomain}/rarityProduction/data/${projectKey}/${tokenId}.json`;

/* begin cdn */


import generateSignedUrl from './generateSignedUrl';

const distributionDomain = 'data.blockviper.com';

export const getChains = () => {
  const url = `https://${distributionDomain}/rarityProduction/chains.json`;
  return generateSignedUrl(url);
};

export const getProjectsJsonUrl = (chainId: string,) => {
  const url = `https://${distributionDomain}/rarityProduction/${chainId}/data/projects.json`;
  return generateSignedUrl(url);
};

export const getProjectJsonUrl = (chainId: string, projectKey: string) => {
  const url = `https://${distributionDomain}/rarityProduction/${chainId}/data/${projectKey}/project.json`;
  return generateSignedUrl(url);
};

export const getNftJsonUrl = (chainId: string, projectKey:string, tokenId: string) => {
  const url = `https://${distributionDomain}/rarityProduction/${chainId}/data/${projectKey}/${tokenId}.json`;
  return generateSignedUrl(url);
};

/* end cdn */

export const getIpfsUrl = (nftUrl:string) => nftUrl.replace('ipfs://', 'https://dweb.link/ipfs/');
export const getOpenSeaTokenUrl = (contractAddress:string, tokenId:string) => `https://opensea.io/assets/${contractAddress}/${tokenId}`;
