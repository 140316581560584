import { useEffect, useState } from 'react';
import { INftProjectMetadata, INftProjectsDocument } from "../common";
import { getProjectsJsonUrl } from '../helpers/urls';
import { changeTheme } from '../helpers/theme';
import { LoadingIndicator } from './icons';
import { ProjectRow } from './projectRow';


export const NftProjectsLoader = ({ chainId }: { chainId: string }) => {
  const [projects, setProjects] = useState(null as null | INftProjectsDocument);

  useEffect(() => {
    (async () => {
      const nftProjectUrl = await getProjectsJsonUrl(chainId); // Here you are correctly using await
      const result = await fetch(nftProjectUrl);
      const obj = (await result.json()) as INftProjectsDocument;
      setProjects(obj);
    })();
  }, []);

  return (
    <>
      {!projects && <LoadingIndicator />}
      {projects && <NftProjects chainId={chainId} projects={projects} />}
    </>
  );
};


export const NftProjects = ({ chainId, projects }: { chainId: string, projects: INftProjectsDocument }) => {
  return (
    <div className="project-list">
      {projects &&
        projects.projects.map((item) => (
          <NftProjectCard chainId={chainId} projectKey={item.projectKey} project={item.projectMetadata} />
        ))}
    </div>
  );
};

export const NftProjectCard = ({ chainId, projectKey, project }: { chainId: string; projectKey: string; project: INftProjectMetadata }) => {
  const targetId = projectKey + '_theme';

  useEffect(() => {
    changeTheme(project.theme, `#${targetId}`);
  }, [project.theme, targetId]);

  return (
    <div
      id={targetId}
      className={'nft-card link'}
      onClick={() => (window.location.href = `${chainId}/${projectKey}`)}
    >
      <ProjectRow project={project} />
    </div>
  );
};
