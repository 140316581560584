export const MarketPlaces = ({ chainId, contractAddress, nftId }: { chainId: string, contractAddress: string, nftId: number }) => {
    return (
        <div>
            <div className='nft-card-image-row'>
                {chainId === 'avalanche' ? (
                    <>
                        <a href={`https://campfire.exchange/collections/${contractAddress}/${nftId}?utm_source=blockViper&utm_medium=rarityAnalyzer&utm_campaign=hiCampfire`} target="_blank" rel="noreferrer"><img src={`/images/${chainId}/campfire.jpg`} alt='campfire' width='50' height='50' /></a>
                        <a href={`https://joepegs.com/item/avalanche/${contractAddress}/${nftId}?utm_source=blockViper&utm_medium=rarityAnalyzer&utm_campaign=hiJoepegs`} target="_blank" rel="noreferrer"><img src={`/images/${chainId}/joepegs.jpg`} alt='joepegs' width='50' height='50' /></a>
                        <a href={`https://marketplace.kalao.io/nft/${contractAddress}_${nftId}?utm_source=blockViper&utm_medium=rarityAnalyzer&utm_campaign=hiKalao`} target="_blank" rel="noreferrer"><img src={`/images/${chainId}/kalao.jpg`} alt='kalao' width='50' height='50' /></a>
                        <a href={`https://salvor.io/collections/${contractAddress}/${nftId}?utm_source=blockViper&utm_medium=rarityAnalyzer&utm_campaign=hiSalvor`} target="_blank" rel="noreferrer"><img src={`/images/${chainId}/salvor.jpg`} alt='salvor' width='50' height='50' /></a>
                        <a href={`https://opensea.io/assets/avalanche/${contractAddress}/${nftId}?utm_source=blockViper&utm_medium=rarityAnalyzer&utm_campaign=hiOpensea`} target="_blank" rel="noreferrer"><img src={`/images/${chainId}/opensea.jpg`} alt='opensea' width='50' height='50' /></a>
                    </>
                ) : chainId === 'metis' ? (
                    <>
                        
                    </>
                ) : chainId === 'ethereum' ? (
                    <>
                        
                    </>
                ) : chainId === 'bora' ? (
                    <>
                        
                    </>
                ) : null}
            </div>
        </div>
    );
};