
import type { INftProjectMetadataDocument } from "../common";
import { SmartImage } from './smart-image';

export const ProjectRow = ({ project }: { project: INftProjectMetadataDocument }) => {
    return (
        <>
            <div className="projectRow">
                    <div className="projectRowImage">
                        <SmartImage alt='project' src={project.image}/>
                    </div>
                    <div className="projectRowItem">
                        <h2>{project.name}</h2>
                        <div className="clippedText">{project.description}</div>
                    </div>
            </div>
        </>
    );
};