export const Layout = ({ children }: { children: JSX.Element }) => {

  return (
    <>
      <div className="siteContainer">
        {children}
      </div>
    </>
  );
};
